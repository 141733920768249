import { Injectable } from '@angular/core';
import {UserApi} from "../../../sdk/services/custom";


@Injectable({
  providedIn: 'root'
})
export class UserDbService {

  constructor(
    private api : UserApi
  ) { }

  listCollaborators = async function (pm:number){
    let data = await this.api.listAssistant(pm).toPromise();
    return data
  }
  addCollaborator = async function (dataCollaborator:any){
    let data = await this.api.addAssistant(dataCollaborator,{}).toPromise();
    console.log(data)
    return data
  }
  deleteCollaborators = async function (id:number){
    let data = await this.api.removeAssistant(id,{}).toPromise();
    return data
  }
  profileGet = async function (){
    let user = await this.api.getCurrentUser({}).toPromise();
    return user
  }
  profileEdit = async function (us:number,dataProfile:any){
    let data = await this.api.patchAttributes(us,dataProfile).toPromise();
    return data
  }
  requestPass = async function (email:string){
    let data = await this.api.passwordRecover(email).toPromise();
    return data
  }
  resetPass = async function (oldPass,newPass){
    let data = await this.api.changePassword(oldPass,newPass).toPromise();
    return data
  }
  setPass = async function (email:string,token:string,newPass:string){
    let data = await this.api.passwordSetByToken(email,token,newPass).toPromise();
    return data
  }
  tokenBraintree = async function (){
    let data = await this.api.braintreeToken({},{}).toPromise();
    return data
  }
  tokenBraintreeNew = async function (token:string){
    let data = await this.api.braintreeToken(token).toPromise();
    return data
  }

  customRegisterCheckinManager = async function (dataCm:any,token:string){
    let data = await this.api.customRegister(dataCm,token).toPromise();
    return data
  }
  customRegisterPropertyManager = async function (dataPm:any){
    let token:string = "NO"
    let data = await this.api.customRegister(dataPm,token).toPromise();
    return data
  }
  getControlFaild = async function (username:string,email:string){
    let data = await this.api.controlUsernameMail(username,email).toPromise();
    return data
  }
  setLogout = async function (){
    await this.api.logout().toPromise();
    
  }
  emailSend = async function (dataEmail:any){
    console.log(`FE user-db dataEmail ${JSON.stringify(dataEmail,null,4)}`)
    return await this.api.sendMessage(dataEmail).toPromise();
  }
}
