/* tslint:disable */

declare var Object: any;
export interface VariableInterface {
  "name"?: string;
  "context"?: string;
  "type"?: string;
  "value"?: string;
  "id"?: number;
}

export class Variable implements VariableInterface {
  "name": string;
  "context": string;
  "type": string;
  "value": string;
  "id": number;
  constructor(data?: VariableInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Variable`.
   */
  public static getModelName() {
    return "Variable";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Variable for dynamic purposes.
  **/
  public static factory(data: VariableInterface): Variable{
    return new Variable(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Variable',
      plural: 'Variables',
      path: 'Variables',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "context": {
          name: 'context',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "value": {
          name: 'value',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
