/* tslint:disable */

declare var Object: any;
export interface SubscriptionTypeInterface {
  "name": string;
  "duration": string;
  "price": number;
  "scanPerMonth": number;
  "maxDevices": number;
  "maxITPoliceCredentials": number;
  "multiUser": boolean;
  "buyable": boolean;
  "priceRooms": number;
  "yearDiscount"?: number;
  "note"?: string;
  "id"?: number;
}

export class SubscriptionType implements SubscriptionTypeInterface {
  "name": string;
  "duration": string;
  "price": number;
  "scanPerMonth": number;
  "maxDevices": number;
  "maxITPoliceCredentials": number;
  "multiUser": boolean;
  "buyable": boolean;
  "priceRooms": number;
  "yearDiscount": number;
  "note": string;
  "id": number;
  constructor(data?: SubscriptionTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SubscriptionType`.
   */
  public static getModelName() {
    return "SubscriptionType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SubscriptionType for dynamic purposes.
  **/
  public static factory(data: SubscriptionTypeInterface): SubscriptionType{
    return new SubscriptionType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SubscriptionType',
      plural: 'SubscriptionTypes',
      path: 'SubscriptionTypes',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "duration": {
          name: 'duration',
          type: 'string',
          default: '1.y'
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "scanPerMonth": {
          name: 'scanPerMonth',
          type: 'number',
          default: -1
        },
        "maxDevices": {
          name: 'maxDevices',
          type: 'number',
          default: 1
        },
        "maxITPoliceCredentials": {
          name: 'maxITPoliceCredentials',
          type: 'number',
          default: 1
        },
        "multiUser": {
          name: 'multiUser',
          type: 'boolean',
          default: false
        },
        "buyable": {
          name: 'buyable',
          type: 'boolean',
          default: false
        },
        "priceRooms": {
          name: 'priceRooms',
          type: 'number'
        },
        "yearDiscount": {
          name: 'yearDiscount',
          type: 'number'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
