/* tslint:disable */
import { Injectable } from '@angular/core';
import { Association } from '../../models/Association';
import { Authorization } from '../../models/Authorization';
import { Certificate } from '../../models/Certificate';
import { Checkin } from '../../models/Checkin';
import { ClientsCoupons } from '../../models/ClientsCoupons';
import { ClientsPayment } from '../../models/ClientsPayment';
import { Coupon } from '../../models/Coupon';
import { Country } from '../../models/Country';
import { Device } from '../../models/Device';
import { ExtraFoldersIstat } from '../../models/ExtraFoldersIstat';
import { Guest } from '../../models/Guest';
import { GuestType } from '../../models/GuestType';
import { IdType } from '../../models/IdType';
import { Invitation } from '../../models/Invitation';
import { Municipality } from '../../models/Municipality';
import { Property } from '../../models/Property';
import { Province } from '../../models/Province';
import { Room } from '../../models/Room';
import { Sending } from '../../models/Sending';
import { Subscription } from '../../models/Subscription';
import { SubscriptionType } from '../../models/SubscriptionType';
import { UserOption } from '../../models/UserOption';
import { TourtaxExemption } from '../../models/TourtaxExemption';
import { TourtaxExemptionV2 } from '../../models/TourtaxExemptionV2';
import { TourtaxUpload } from '../../models/TourtaxUpload';
import { IstatUpload } from '../../models/IstatUpload';
import { Variable } from '../../models/Variable';
import { QueueJob } from '../../models/QueueJob';
import { User } from '../../models/User';
import { RoleMapping } from '../../models/RoleMapping';
import { Role } from '../../models/Role';
import { TourtaxParameters } from '../../models/TourtaxParameters';
import { LogUpdate } from '../../models/LogUpdate';
import { GlobalParameters } from '../../models/GlobalParameters';
import { PropertyType } from '../../models/PropertyType';
import { Guide } from '../../models/Guide';
import { RoomsOccupied } from '../../models/RoomsOccupied';
import { ContractUpload } from '../../models/ContractUpload';
import { Host } from '../../models/Host';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Association: Association,
    Authorization: Authorization,
    Certificate: Certificate,
    Checkin: Checkin,
    ClientsCoupons: ClientsCoupons,
    ClientsPayment: ClientsPayment,
    Coupon: Coupon,
    Country: Country,
    Device: Device,
    ExtraFoldersIstat: ExtraFoldersIstat,
    Guest: Guest,
    GuestType: GuestType,
    IdType: IdType,
    Invitation: Invitation,
    Municipality: Municipality,
    Property: Property,
    Province: Province,
    Room: Room,
    Sending: Sending,
    Subscription: Subscription,
    SubscriptionType: SubscriptionType,
    UserOption: UserOption,
    TourtaxExemption: TourtaxExemption,
    TourtaxExemptionV2: TourtaxExemptionV2,
    TourtaxUpload: TourtaxUpload,
    IstatUpload: IstatUpload,
    Variable: Variable,
    QueueJob: QueueJob,
    User: User,
    RoleMapping: RoleMapping,
    Role: Role,
    TourtaxParameters: TourtaxParameters,
    LogUpdate: LogUpdate,
    GlobalParameters: GlobalParameters,
    PropertyType: PropertyType,
    Guide: Guide,
    RoomsOccupied: RoomsOccupied,
    ContractUpload: ContractUpload,
    Host: Host,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
