/* tslint:disable */
import {
  User
} from '../index';

declare var Object: any;
export interface InvitationInterface {
  "context": string;
  "email": string;
  "token": string;
  "id"?: number;
  "owner_id"?: number;
  owner?: User;
}

export class Invitation implements InvitationInterface {
  "context": string;
  "email": string;
  "token": string;
  "id": number;
  "owner_id": number;
  owner: User;
  constructor(data?: InvitationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Invitation`.
   */
  public static getModelName() {
    return "Invitation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Invitation for dynamic purposes.
  **/
  public static factory(data: InvitationInterface): Invitation{
    return new Invitation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Invitation',
      plural: 'Invitations',
      path: 'Invitations',
      idName: 'id',
      properties: {
        "context": {
          name: 'context',
          type: 'string',
          default: 'cm'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "token": {
          name: 'token',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
      },
      relations: {
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
      }
    }
  }
}
