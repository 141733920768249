/* tslint:disable */
import {
  Coupon,
  User
} from '../index';

declare var Object: any;
export interface ClientsCouponsInterface {
  "client_id": number;
  "coupon_id": number;
  "applied": boolean;
  "subscription_id": number;
  "id"?: number;
  coupons?: Coupon;
  clients?: User;
}

export class ClientsCoupons implements ClientsCouponsInterface {
  "client_id": number;
  "coupon_id": number;
  "applied": boolean;
  "subscription_id": number;
  "id": number;
  coupons: Coupon;
  clients: User;
  constructor(data?: ClientsCouponsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ClientsCoupons`.
   */
  public static getModelName() {
    return "ClientsCoupons";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ClientsCoupons for dynamic purposes.
  **/
  public static factory(data: ClientsCouponsInterface): ClientsCoupons{
    return new ClientsCoupons(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ClientsCoupons',
      plural: 'ClientsCoupons',
      path: 'ClientsCoupons',
      idName: 'id',
      properties: {
        "client_id": {
          name: 'client_id',
          type: 'number'
        },
        "coupon_id": {
          name: 'coupon_id',
          type: 'number'
        },
        "applied": {
          name: 'applied',
          type: 'boolean',
          default: false
        },
        "subscription_id": {
          name: 'subscription_id',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        coupons: {
          name: 'coupons',
          type: 'Coupon',
          model: 'Coupon',
          relationType: 'belongsTo',
                  keyFrom: 'coupon_id',
          keyTo: 'id'
        },
        clients: {
          name: 'clients',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'client_id',
          keyTo: 'id'
        },
      }
    }
  }
}
