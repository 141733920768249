import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataValidetorService {

  constructor() { }

  isFull (val){
    let type = typeof(val)
    let result = this[`cnt_${type}`](val)
    return result
}

cnt_undefined(val){return false}

cnt_boolean(val){
   if(val === true || val ===false) {
        return true
    }else{
        return false
    }
}

cnt_number(val){
    if(isNaN(val)){
        return false
    }else{
        return true
    }
}

cnt_bigint(val){
    if(isNaN(val)){
        return false
    }else{
        return true
    }
}

cnt_string(val){
    if(val.length===0){
        return false
    }else{
        return true
    }
}
cnt_simbol(val){return true}

cnt_function(val){return true}

cnt_object(val){
    if(Array.isArray(val)){if(val.length >0){return true}else{return false}}
    if (val ===null){return false}
    if (this.isEmpty(val)){return false}else{return true}
}

isEmpty (obj) {
    for(var i in obj) { return false; }
    return true;
  }

}
