/* tslint:disable */
import {
  Guest,
  Property,
  TourtaxExemptionV2,
  TourtaxParameters
} from '../index';

declare var Object: any;
export interface MunicipalityInterface {
  "code": string;
  "name": string;
  "cap"?: string;
  "province": string;
  "tourtaxHandler"?: string;
  "tourtaxData"?: string;
  "istatHandler"?: string;
  "istatData"?: string;
  "from"?: Date;
  "to"?: Date;
  "codeCatastale"?: string;
  "idProvinciaRicestatUnicom"?: string;
  "id"?: number;
  issued?: Guest[];
  originated?: Guest[];
  property?: Property[];
  exemption?: TourtaxExemptionV2[];
  ttxParameters?: TourtaxParameters[];
}

export class Municipality implements MunicipalityInterface {
  "code": string;
  "name": string;
  "cap": string;
  "province": string;
  "tourtaxHandler": string;
  "tourtaxData": string;
  "istatHandler": string;
  "istatData": string;
  "from": Date;
  "to": Date;
  "codeCatastale": string;
  "idProvinciaRicestatUnicom": string;
  "id": number;
  issued: Guest[];
  originated: Guest[];
  property: Property[];
  exemption: TourtaxExemptionV2[];
  ttxParameters: TourtaxParameters[];
  constructor(data?: MunicipalityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Municipality`.
   */
  public static getModelName() {
    return "Municipality";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Municipality for dynamic purposes.
  **/
  public static factory(data: MunicipalityInterface): Municipality{
    return new Municipality(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Municipality',
      plural: 'municipalities',
      path: 'municipalities',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "cap": {
          name: 'cap',
          type: 'string'
        },
        "province": {
          name: 'province',
          type: 'string'
        },
        "tourtaxHandler": {
          name: 'tourtaxHandler',
          type: 'string'
        },
        "tourtaxData": {
          name: 'tourtaxData',
          type: 'string'
        },
        "istatHandler": {
          name: 'istatHandler',
          type: 'string'
        },
        "istatData": {
          name: 'istatData',
          type: 'string'
        },
        "from": {
          name: 'from',
          type: 'Date'
        },
        "to": {
          name: 'to',
          type: 'Date'
        },
        "codeCatastale": {
          name: 'codeCatastale',
          type: 'string'
        },
        "idProvinciaRicestatUnicom": {
          name: 'idProvinciaRicestatUnicom',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        issued: {
          name: 'issued',
          type: 'Guest[]',
          model: 'Guest',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'issuerId'
        },
        originated: {
          name: 'originated',
          type: 'Guest[]',
          model: 'Guest',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'originId'
        },
        property: {
          name: 'property',
          type: 'Property[]',
          model: 'Property',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'municipality_id'
        },
        exemption: {
          name: 'exemption',
          type: 'TourtaxExemptionV2[]',
          model: 'TourtaxExemptionV2',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'municipality_id'
        },
        ttxParameters: {
          name: 'ttxParameters',
          type: 'TourtaxParameters[]',
          model: 'TourtaxParameters',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'municipality_id'
        },
      }
    }
  }
}
