/* tslint:disable */
import {
  SubscriptionType,
  User
} from '../index';

declare var Object: any;
export interface SubscriptionInterface {
  "start": Date;
  "expire": Date;
  "active"?: boolean;
  "nextPrice": number;
  "newExpire"?: Date;
  "note"?: string;
  "coupon_code"?: string;
  "numProperties": number;
  "numRooms": number;
  "renewable"?: boolean;
  "id"?: number;
  "type_id"?: number;
  "owner_id"?: number;
  type?: SubscriptionType;
  owner?: User;
}

export class Subscription implements SubscriptionInterface {
  "start": Date;
  "expire": Date;
  "active": boolean;
  "nextPrice": number;
  "newExpire": Date;
  "note": string;
  "coupon_code": string;
  "numProperties": number;
  "numRooms": number;
  "renewable": boolean;
  "id": number;
  "type_id": number;
  "owner_id": number;
  type: SubscriptionType;
  owner: User;
  constructor(data?: SubscriptionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Subscription`.
   */
  public static getModelName() {
    return "Subscription";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Subscription for dynamic purposes.
  **/
  public static factory(data: SubscriptionInterface): Subscription{
    return new Subscription(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Subscription',
      plural: 'Subscriptions',
      path: 'Subscriptions',
      idName: 'id',
      properties: {
        "start": {
          name: 'start',
          type: 'Date'
        },
        "expire": {
          name: 'expire',
          type: 'Date'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "nextPrice": {
          name: 'nextPrice',
          type: 'number'
        },
        "newExpire": {
          name: 'newExpire',
          type: 'Date'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "coupon_code": {
          name: 'coupon_code',
          type: 'string'
        },
        "numProperties": {
          name: 'numProperties',
          type: 'number'
        },
        "numRooms": {
          name: 'numRooms',
          type: 'number'
        },
        "renewable": {
          name: 'renewable',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "type_id": {
          name: 'type_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
      },
      relations: {
        type: {
          name: 'type',
          type: 'SubscriptionType',
          model: 'SubscriptionType',
          relationType: 'belongsTo',
                  keyFrom: 'type_id',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
      }
    }
  }
}
