/* tslint:disable */
import {
  User
} from '../index';

declare var Object: any;
export interface DeviceInterface {
  "name": string;
  "type": string;
  "deviceId": string;
  "registrationId"?: string;
  "registrationType"?: string;
  "firebaseId"?: string;
  "id"?: number;
  "owner_id"?: number;
  "user_id"?: number;
  owner?: User;
  user?: User;
}

export class Device implements DeviceInterface {
  "name": string;
  "type": string;
  "deviceId": string;
  "registrationId": string;
  "registrationType": string;
  "firebaseId": string;
  "id": number;
  "owner_id": number;
  "user_id": number;
  owner: User;
  user: User;
  constructor(data?: DeviceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Device`.
   */
  public static getModelName() {
    return "Device";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Device for dynamic purposes.
  **/
  public static factory(data: DeviceInterface): Device{
    return new Device(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Device',
      plural: 'Devices',
      path: 'Devices',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "deviceId": {
          name: 'deviceId',
          type: 'string'
        },
        "registrationId": {
          name: 'registrationId',
          type: 'string'
        },
        "registrationType": {
          name: 'registrationType',
          type: 'string'
        },
        "firebaseId": {
          name: 'firebaseId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
        "user_id": {
          name: 'user_id',
          type: 'number'
        },
      },
      relations: {
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'user_id',
          keyTo: 'id'
        },
      }
    }
  }
}
