/* tslint:disable */

declare var Object: any;
export interface RoomsOccupiedInterface {
  "Property_id": number;
  "owner_id": number;
  "detectionDate": number;
  "quantity": number;
  "dataPresenze": string;
  "id"?: number;
}

export class RoomsOccupied implements RoomsOccupiedInterface {
  "Property_id": number;
  "owner_id": number;
  "detectionDate": number;
  "quantity": number;
  "dataPresenze": string;
  "id": number;
  constructor(data?: RoomsOccupiedInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RoomsOccupied`.
   */
  public static getModelName() {
    return "RoomsOccupied";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RoomsOccupied for dynamic purposes.
  **/
  public static factory(data: RoomsOccupiedInterface): RoomsOccupied{
    return new RoomsOccupied(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RoomsOccupied',
      plural: 'RoomsOccupieds',
      path: 'RoomsOccupieds',
      idName: 'id',
      properties: {
        "Property_id": {
          name: 'Property_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
        "detectionDate": {
          name: 'detectionDate',
          type: 'number'
        },
        "quantity": {
          name: 'quantity',
          type: 'number',
          default: 0
        },
        "dataPresenze": {
          name: 'dataPresenze',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
