/* tslint:disable */

declare var Object: any;
export interface ContractUploadInterface {
  "checkin_id": number;
  "pathContract": string;
  "emailNoSign": string;
  "dataEmailNoSign": Date;
  "owner_id": number;
  "otpValue"?: string;
  "otpTime"?: Date;
  "dataMail"?: Date;
  "otpMail"?: string;
  "otpGuest"?: number;
  "finale": string;
  "canale": string;
  "id"?: number;
}

export class ContractUpload implements ContractUploadInterface {
  "checkin_id": number;
  "pathContract": string;
  "emailNoSign": string;
  "dataEmailNoSign": Date;
  "owner_id": number;
  "otpValue": string;
  "otpTime": Date;
  "dataMail": Date;
  "otpMail": string;
  "otpGuest": number;
  "finale": string;
  "canale": string;
  "id": number;
  constructor(data?: ContractUploadInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ContractUpload`.
   */
  public static getModelName() {
    return "ContractUpload";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ContractUpload for dynamic purposes.
  **/
  public static factory(data: ContractUploadInterface): ContractUpload{
    return new ContractUpload(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ContractUpload',
      plural: 'ContractUploads',
      path: 'ContractUploads',
      idName: 'id',
      properties: {
        "checkin_id": {
          name: 'checkin_id',
          type: 'number'
        },
        "pathContract": {
          name: 'pathContract',
          type: 'string'
        },
        "emailNoSign": {
          name: 'emailNoSign',
          type: 'string'
        },
        "dataEmailNoSign": {
          name: 'dataEmailNoSign',
          type: 'Date'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
        "otpValue": {
          name: 'otpValue',
          type: 'string'
        },
        "otpTime": {
          name: 'otpTime',
          type: 'Date'
        },
        "dataMail": {
          name: 'dataMail',
          type: 'Date'
        },
        "otpMail": {
          name: 'otpMail',
          type: 'string'
        },
        "otpGuest": {
          name: 'otpGuest',
          type: 'number'
        },
        "finale": {
          name: 'finale',
          type: 'string'
        },
        "canale": {
          name: 'canale',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
