/* tslint:disable */
import {
  Guest
} from '../index';

declare var Object: any;
export interface CountryInterface {
  "code": string;
  "name": string;
  "from"?: Date;
  "to"?: Date;
  "iso3166_1"?: string;
  "iso3166_2"?: string;
  "istatCode"?: string;
  "istatCode1"?: string;
  "turiweb"?: string;
  "tolmCode"?: string;
  "tolmName"?: string;
  "id"?: number;
  issued?: Guest[];
  originated?: Guest[];
}

export class Country implements CountryInterface {
  "code": string;
  "name": string;
  "from": Date;
  "to": Date;
  "iso3166_1": string;
  "iso3166_2": string;
  "istatCode": string;
  "istatCode1": string;
  "turiweb": string;
  "tolmCode": string;
  "tolmName": string;
  "id": number;
  issued: Guest[];
  originated: Guest[];
  constructor(data?: CountryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Country`.
   */
  public static getModelName() {
    return "Country";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Country for dynamic purposes.
  **/
  public static factory(data: CountryInterface): Country{
    return new Country(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Country',
      plural: 'countries',
      path: 'countries',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "from": {
          name: 'from',
          type: 'Date'
        },
        "to": {
          name: 'to',
          type: 'Date'
        },
        "iso3166_1": {
          name: 'iso3166_1',
          type: 'string'
        },
        "iso3166_2": {
          name: 'iso3166_2',
          type: 'string'
        },
        "istatCode": {
          name: 'istatCode',
          type: 'string'
        },
        "istatCode1": {
          name: 'istatCode1',
          type: 'string'
        },
        "turiweb": {
          name: 'turiweb',
          type: 'string'
        },
        "tolmCode": {
          name: 'tolmCode',
          type: 'string'
        },
        "tolmName": {
          name: 'tolmName',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        issued: {
          name: 'issued',
          type: 'Guest[]',
          model: 'Guest',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'issuerId'
        },
        originated: {
          name: 'originated',
          type: 'Guest[]',
          model: 'Guest',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'originId'
        },
      }
    }
  }
}
