/* tslint:disable */
import {
  Municipality,
  User
} from '../index';

declare var Object: any;
export interface TourtaxExemptionInterface {
  "id"?: number;
  "exemptions": any;
  "municipality_id"?: number;
  "owner_id"?: number;
  municipality?: Municipality;
  owner?: User;
}

export class TourtaxExemption implements TourtaxExemptionInterface {
  "id": number;
  "exemptions": any;
  "municipality_id": number;
  "owner_id": number;
  municipality: Municipality;
  owner: User;
  constructor(data?: TourtaxExemptionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TourtaxExemption`.
   */
  public static getModelName() {
    return "TourtaxExemption";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TourtaxExemption for dynamic purposes.
  **/
  public static factory(data: TourtaxExemptionInterface): TourtaxExemption{
    return new TourtaxExemption(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TourtaxExemption',
      plural: 'TourtaxExemptions',
      path: 'TourtaxExemptions',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "exemptions": {
          name: 'exemptions',
          type: 'any'
        },
        "municipality_id": {
          name: 'municipality_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
      },
      relations: {
        municipality: {
          name: 'municipality',
          type: 'Municipality',
          model: 'Municipality',
          relationType: 'belongsTo',
                  keyFrom: 'municipality_id',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
      }
    }
  }
}
