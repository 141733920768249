/* tslint:disable */

declare var Object: any;
export interface LogUpdateInterface {
  "table": string;
  "data_update": Date;
  "fieldName": string;
  "oldValue": string;
  "newValue": string;
  "owner_id": number;
  "subscriber_id": number;
  "note"?: string;
  "id"?: number;
}

export class LogUpdate implements LogUpdateInterface {
  "table": string;
  "data_update": Date;
  "fieldName": string;
  "oldValue": string;
  "newValue": string;
  "owner_id": number;
  "subscriber_id": number;
  "note": string;
  "id": number;
  constructor(data?: LogUpdateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `LogUpdate`.
   */
  public static getModelName() {
    return "LogUpdate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of LogUpdate for dynamic purposes.
  **/
  public static factory(data: LogUpdateInterface): LogUpdate{
    return new LogUpdate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LogUpdate',
      plural: 'LogUpdates',
      path: 'LogUpdates',
      idName: 'id',
      properties: {
        "table": {
          name: 'table',
          type: 'string'
        },
        "data_update": {
          name: 'data_update',
          type: 'Date'
        },
        "fieldName": {
          name: 'fieldName',
          type: 'string'
        },
        "oldValue": {
          name: 'oldValue',
          type: 'string'
        },
        "newValue": {
          name: 'newValue',
          type: 'string'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
        "subscriber_id": {
          name: 'subscriber_id',
          type: 'number'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
