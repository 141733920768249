/* tslint:disable */
import {
  User
} from '../index';

declare var Object: any;
export interface CouponInterface {
  "discount"?: number;
  "discount_type": string;
  "description": string;
  "code"?: string;
  "client_id"?: number;
  "active"?: boolean;
  "count"?: number;
  "used"?: number;
  "expiration"?: Date;
  "reusable"?: boolean;
  "n_reusable"?: number;
  "duration"?: string;
  "id"?: number;
  customer?: User;
  clients?: User[];
}

export class Coupon implements CouponInterface {
  "discount": number;
  "discount_type": string;
  "description": string;
  "code": string;
  "client_id": number;
  "active": boolean;
  "count": number;
  "used": number;
  "expiration": Date;
  "reusable": boolean;
  "n_reusable": number;
  "duration": string;
  "id": number;
  customer: User;
  clients: User[];
  constructor(data?: CouponInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Coupon`.
   */
  public static getModelName() {
    return "Coupon";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Coupon for dynamic purposes.
  **/
  public static factory(data: CouponInterface): Coupon{
    return new Coupon(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Coupon',
      plural: 'Coupons',
      path: 'Coupons',
      idName: 'id',
      properties: {
        "discount": {
          name: 'discount',
          type: 'number'
        },
        "discount_type": {
          name: 'discount_type',
          type: 'string',
          default: 'percentage'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "client_id": {
          name: 'client_id',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "count": {
          name: 'count',
          type: 'number'
        },
        "used": {
          name: 'used',
          type: 'number',
          default: 0
        },
        "expiration": {
          name: 'expiration',
          type: 'Date'
        },
        "reusable": {
          name: 'reusable',
          type: 'boolean',
          default: false
        },
        "n_reusable": {
          name: 'n_reusable',
          type: 'number',
          default: 0
        },
        "duration": {
          name: 'duration',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        customer: {
          name: 'customer',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'client_id',
          keyTo: 'id'
        },
        clients: {
          name: 'clients',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
          modelThrough: 'ClientsCoupons',
          keyThrough: 'coupon_id',
          keyFrom: 'id',
          keyTo: 'client_id'
        },
      }
    }
  }
}
