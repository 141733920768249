/* tslint:disable */
import {
  Property,
  User
} from '../index';

declare var Object: any;
export interface CertificateInterface {
  "name": string;
  "username": string;
  "description"?: string;
  "context": string;
  "format"?: string;
  "validFrom"?: Date;
  "validTo"?: Date;
  "password": string;
  "webPassword"?: string;
  "size"?: number;
  "wskey"?: string;
  "codePropertyPolice"?: number;
  "id"?: number;
  "property_id"?: number;
  "owner_id"?: number;
  property?: Property;
  owner?: User;
}

export class Certificate implements CertificateInterface {
  "name": string;
  "username": string;
  "description": string;
  "context": string;
  "format": string;
  "validFrom": Date;
  "validTo": Date;
  "password": string;
  "webPassword": string;
  "size": number;
  "wskey": string;
  "codePropertyPolice": number;
  "id": number;
  "property_id": number;
  "owner_id": number;
  property: Property;
  owner: User;
  constructor(data?: CertificateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Certificate`.
   */
  public static getModelName() {
    return "Certificate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Certificate for dynamic purposes.
  **/
  public static factory(data: CertificateInterface): Certificate{
    return new Certificate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Certificate',
      plural: 'Certificates',
      path: 'Certificates',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "context": {
          name: 'context',
          type: 'string'
        },
        "format": {
          name: 'format',
          type: 'string'
        },
        "validFrom": {
          name: 'validFrom',
          type: 'Date'
        },
        "validTo": {
          name: 'validTo',
          type: 'Date'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
        "webPassword": {
          name: 'webPassword',
          type: 'string'
        },
        "size": {
          name: 'size',
          type: 'number'
        },
        "wskey": {
          name: 'wskey',
          type: 'string'
        },
        "codePropertyPolice": {
          name: 'codePropertyPolice',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "property_id": {
          name: 'property_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
      },
      relations: {
        property: {
          name: 'property',
          type: 'Property',
          model: 'Property',
          relationType: 'belongsTo',
                  keyFrom: 'property_id',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
      }
    }
  }
}
