/* tslint:disable */
import {
  Guest,
  TourtaxUpload,
  IstatUpload,
  User
} from '../index';

declare var Object: any;
export interface CheckinInterface {
  "ExpectedPax"?: number;
  "status": string;
  "sendingData"?: any;
  "token": string;
  "email"?: string;
  "from"?: Date;
  "to"?: Date;
  "reservationData"?: any;
  "tourtaxChannelManaged": boolean;
  "reservationID"?: string;
  "notaCK"?: string;
  "targa"?: string;
  "importoLocazione"?: string;
  "importoCaparra"?: string;
  "metodoPagamento"?: string;
  "id"?: number;
  "accommodationId"?: number;
  "accommodationType"?: string;
  "tourtaxSending_id"?: number;
  "istatSending_id"?: number;
  "owner_id"?: number;
  "subscriber_id"?: number;
  accommodation?: any;
  guests?: Guest[];
  tourtaxSending?: TourtaxUpload;
  istatSending?: IstatUpload;
  owner?: User;
  subscriber?: User;
}

export class Checkin implements CheckinInterface {
  "ExpectedPax": number;
  "status": string;
  "sendingData": any;
  "token": string;
  "email": string;
  "from": Date;
  "to": Date;
  "reservationData": any;
  "tourtaxChannelManaged": boolean;
  "reservationID": string;
  "notaCK": string;
  "targa": string;
  "importoLocazione": string;
  "importoCaparra": string;
  "metodoPagamento": string;
  "id": number;
  "accommodationId": number;
  "accommodationType": string;
  "tourtaxSending_id": number;
  "istatSending_id": number;
  "owner_id": number;
  "subscriber_id": number;
  accommodation: any;
  guests: Guest[];
  tourtaxSending: TourtaxUpload;
  istatSending: IstatUpload;
  owner: User;
  subscriber: User;
  constructor(data?: CheckinInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Checkin`.
   */
  public static getModelName() {
    return "Checkin";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Checkin for dynamic purposes.
  **/
  public static factory(data: CheckinInterface): Checkin{
    return new Checkin(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Checkin',
      plural: 'Checkins',
      path: 'Checkins',
      idName: 'id',
      properties: {
        "ExpectedPax": {
          name: 'ExpectedPax',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string',
          default: 'waiting'
        },
        "sendingData": {
          name: 'sendingData',
          type: 'any'
        },
        "token": {
          name: 'token',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "from": {
          name: 'from',
          type: 'Date'
        },
        "to": {
          name: 'to',
          type: 'Date'
        },
        "reservationData": {
          name: 'reservationData',
          type: 'any'
        },
        "tourtaxChannelManaged": {
          name: 'tourtaxChannelManaged',
          type: 'boolean',
          default: false
        },
        "reservationID": {
          name: 'reservationID',
          type: 'string'
        },
        "notaCK": {
          name: 'notaCK',
          type: 'string'
        },
        "targa": {
          name: 'targa',
          type: 'string'
        },
        "importoLocazione": {
          name: 'importoLocazione',
          type: 'string'
        },
        "importoCaparra": {
          name: 'importoCaparra',
          type: 'string'
        },
        "metodoPagamento": {
          name: 'metodoPagamento',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "accommodationId": {
          name: 'accommodationId',
          type: 'number'
        },
        "accommodationType": {
          name: 'accommodationType',
          type: 'string'
        },
        "tourtaxSending_id": {
          name: 'tourtaxSending_id',
          type: 'number'
        },
        "istatSending_id": {
          name: 'istatSending_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
        "subscriber_id": {
          name: 'subscriber_id',
          type: 'number'
        },
      },
      relations: {
        accommodation: {
          name: 'accommodation',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'accommodationId',
          keyTo: 'id'
        },
        guests: {
          name: 'guests',
          type: 'Guest[]',
          model: 'Guest',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'checkin_id'
        },
        tourtaxSending: {
          name: 'tourtaxSending',
          type: 'TourtaxUpload',
          model: 'TourtaxUpload',
          relationType: 'belongsTo',
                  keyFrom: 'tourtaxSending_id',
          keyTo: 'id'
        },
        istatSending: {
          name: 'istatSending',
          type: 'IstatUpload',
          model: 'IstatUpload',
          relationType: 'belongsTo',
                  keyFrom: 'istatSending_id',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
        subscriber: {
          name: 'subscriber',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'subscriber_id',
          keyTo: 'id'
        },
      }
    }
  }
}
