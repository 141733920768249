import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { UserStoreService} from '../../../pages/facilecheckin/services-db/user-store.service'
import { BaseurlService } from '../../../pages/facilecheckin/services/baseurl.service';
import { Router} from "@angular/router";

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  logo60 : string = ""
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  active:boolean= false
  expired:boolean = false

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    /*{
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },*/
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Cambia password' }, { title: 'Cambia metodo di pagamento' }, { title: 'Pagamenti' }, { title: 'Log out' } ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private userStore: UserStoreService,
    private breakpointService: NbMediaBreakpointsService,
    private bs : BaseurlService,
    public  router        : Router,
    
    ) {
  }

  async ngOnInit() {
    this.logo60 = `${this.bs.baseUrlImg}FCI_logo80.jpg`
    // verifico se lo scopes è nullo per visualizzare il pulsante del menu
    let scope = await this.userStore.getScopes()
console.log("In header component scope:",scope)
    if(scope ==''){this.active= true}
    if(scope =='subscription'){this.expired= true}
    this.currentTheme = this.themeService.currentTheme;
    this.user= {name:this.userStore.getFullname() ,picture:'assets/images/user.png'}
    let role = this.userStore.getRole_id()
    if (role==3){
      this.userMenu = [ { title: 'Il tuo profilo' }, { title: 'Cambia password' }, { title: 'Cambia metodo di pagamento' }, { title: 'Rinnovo abbonamento' },{ title: 'Ottieni assistenza' }, { title: 'Guida' }, { title: 'Log out' } ];
    }else if (role>3){
      this.userMenu = [ { title: 'Il tuo profilo' }, { title: 'Cambia password' },  { title: 'Ottieni assistenza' }, { title: 'Guida' }, { title: 'Log out' } ];
    }else {
      this.userMenu = [ { title: 'Log out' } ];
    }
/*
    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => {
        this.user = users.current
      });
*/
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    /*this.menuService.navigateHome();
    return false;*/
  }
  onClickEmail(){
    console.log("passo per il click menu")
    this.router.navigate(['../../../pages/facilecheckin/frm-email'] );
  }
  onExpired(){
    this.router.navigate(['../../../pages/facilecheckin/pos-stepper'] );
  }
}
