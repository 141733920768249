import { Injectable } from '@angular/core';
import {UserDbService} from "../services-db/user-db.service"
import {InvitationDbService} from "../services-db/invitation-db.service"

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private api : UserDbService,
    private apiInvite : InvitationDbService
  ) { }

  collaboratorsList = async function (pm:number){
    let data = await this.api.listCollaborators(pm)
    return data
  }
  collaboratorAdd = async function (dataCollaborator:any){
    let data = await this.api.addCollaborator(dataCollaborator)
    console.log(data)
    return data
  }
  collaboratorDelete = async function (id:number){
    let data = await this.api.deleteCollaborators(id)
    return data
  }
  profileGetData = async function (){
    let data = await this.api.profileGet()
    return data
  }
  profileEditData = async function (us:number,dataProfile:any){
    let data = await this.api.profileEdit(us,dataProfile)
    return data
  }
  passwordRequest = async function (email:string){
    let data = await this.api.requestPass(email)
    return data
  }
  passwordReset = async function (oldPass,newPass){
    let data = await this.api.resetPass(oldPass,newPass)
    return data
  }
  passwordSet = async function (email:string,token:string,newPass:string){
    let data = await this.api.setPass(email,token,newPass);
    return data
  }
  cmInvitation= async function (email:string){
    let data = await this.apiInvite.sendInvitation(email);
    return data
  }
  checkinManagerList = async function (){
    let data = await this.api.listCheckinnManager();
    return data
  }
  registerCheckinManager = async function (dataCm:any,token:string){
    let data = await this.api.customRegisterCheckinManager(dataCm,token);
    return data
  }
  registerPropertyManager = async function (dataPm:any){
    let data = await this.api.customRegisterPropertyManager(dataPm);
    return data
  }
  controlFaild = async function (username:string,email:string){
    let data = await this.api.getControlFaild(username,email);
    return data
  }
  Logout = async function (){
    await this.api.setLogout();

  }
  sendMessage = async function (dataEmail:any){
    return await this.api.emailSend(dataEmail);
  }
}
