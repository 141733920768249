/* tslint:disable */
import {
  Municipality,
  Property,
  User
} from '../index';

declare var Object: any;
export interface IstatUploadInterface {
  "regione": string;
  "path": string;
  "fromDate": Date;
  "toDate": Date;
  "dataUpload": Date;
  "id"?: number;
  "municipality_id"?: number;
  "property_id"?: number;
  "owner_id"?: number;
  municipality?: Municipality;
  property?: Property;
  owner?: User;
}

export class IstatUpload implements IstatUploadInterface {
  "regione": string;
  "path": string;
  "fromDate": Date;
  "toDate": Date;
  "dataUpload": Date;
  "id": number;
  "municipality_id": number;
  "property_id": number;
  "owner_id": number;
  municipality: Municipality;
  property: Property;
  owner: User;
  constructor(data?: IstatUploadInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `IstatUpload`.
   */
  public static getModelName() {
    return "IstatUpload";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of IstatUpload for dynamic purposes.
  **/
  public static factory(data: IstatUploadInterface): IstatUpload{
    return new IstatUpload(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'IstatUpload',
      plural: 'IstatUploads',
      path: 'IstatUploads',
      idName: 'id',
      properties: {
        "regione": {
          name: 'regione',
          type: 'string'
        },
        "path": {
          name: 'path',
          type: 'string'
        },
        "fromDate": {
          name: 'fromDate',
          type: 'Date'
        },
        "toDate": {
          name: 'toDate',
          type: 'Date'
        },
        "dataUpload": {
          name: 'dataUpload',
          type: 'Date',
          default: new Date(0)
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "municipality_id": {
          name: 'municipality_id',
          type: 'number'
        },
        "property_id": {
          name: 'property_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
      },
      relations: {
        municipality: {
          name: 'municipality',
          type: 'Municipality',
          model: 'Municipality',
          relationType: 'belongsTo',
                  keyFrom: 'municipality_id',
          keyTo: 'id'
        },
        property: {
          name: 'property',
          type: 'Property',
          model: 'Property',
          relationType: 'belongsTo',
                  keyFrom: 'property_id',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
      }
    }
  }
}
