/* tslint:disable */
import {
  Municipality,
  Property,
  User
} from '../index';

declare var Object: any;
export interface TourtaxUploadInterface {
  "pathFolder": string;
  "fileName": string;
  "extension": string;
  "typeCalculation": string;
  "dataIn"?: Date;
  "dataOut"?: Date;
  "dataUpload": Date;
  "id"?: number;
  "municipality_id"?: number;
  "property_id"?: number;
  "owner_id"?: number;
  municipality?: Municipality;
  property?: Property;
  owner?: User;
}

export class TourtaxUpload implements TourtaxUploadInterface {
  "pathFolder": string;
  "fileName": string;
  "extension": string;
  "typeCalculation": string;
  "dataIn": Date;
  "dataOut": Date;
  "dataUpload": Date;
  "id": number;
  "municipality_id": number;
  "property_id": number;
  "owner_id": number;
  municipality: Municipality;
  property: Property;
  owner: User;
  constructor(data?: TourtaxUploadInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TourtaxUpload`.
   */
  public static getModelName() {
    return "TourtaxUpload";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TourtaxUpload for dynamic purposes.
  **/
  public static factory(data: TourtaxUploadInterface): TourtaxUpload{
    return new TourtaxUpload(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TourtaxUpload',
      plural: 'TourtaxUploads',
      path: 'TourtaxUploads',
      idName: 'id',
      properties: {
        "pathFolder": {
          name: 'pathFolder',
          type: 'string'
        },
        "fileName": {
          name: 'fileName',
          type: 'string'
        },
        "extension": {
          name: 'extension',
          type: 'string'
        },
        "typeCalculation": {
          name: 'typeCalculation',
          type: 'string'
        },
        "dataIn": {
          name: 'dataIn',
          type: 'Date'
        },
        "dataOut": {
          name: 'dataOut',
          type: 'Date'
        },
        "dataUpload": {
          name: 'dataUpload',
          type: 'Date',
          default: new Date(0)
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "municipality_id": {
          name: 'municipality_id',
          type: 'number'
        },
        "property_id": {
          name: 'property_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
      },
      relations: {
        municipality: {
          name: 'municipality',
          type: 'Municipality',
          model: 'Municipality',
          relationType: 'belongsTo',
                  keyFrom: 'municipality_id',
          keyTo: 'id'
        },
        property: {
          name: 'property',
          type: 'Property',
          model: 'Property',
          relationType: 'belongsTo',
                  keyFrom: 'property_id',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
      }
    }
  }
}
