/* tslint:disable */
import {
  Municipality
} from '../index';

declare var Object: any;
export interface TourtaxParametersInterface {
  "contex": string;
  "code"?: string;
  "value": string;
  "year": number;
  "dataInsert": Date;
  "id"?: number;
  "municipality_id"?: number;
  municipality?: Municipality;
}

export class TourtaxParameters implements TourtaxParametersInterface {
  "contex": string;
  "code": string;
  "value": string;
  "year": number;
  "dataInsert": Date;
  "id": number;
  "municipality_id": number;
  municipality: Municipality;
  constructor(data?: TourtaxParametersInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TourtaxParameters`.
   */
  public static getModelName() {
    return "TourtaxParameters";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TourtaxParameters for dynamic purposes.
  **/
  public static factory(data: TourtaxParametersInterface): TourtaxParameters{
    return new TourtaxParameters(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TourtaxParameters',
      plural: 'TourtaxParameters',
      path: 'TourtaxParameters',
      idName: 'id',
      properties: {
        "contex": {
          name: 'contex',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "value": {
          name: 'value',
          type: 'string'
        },
        "year": {
          name: 'year',
          type: 'number'
        },
        "dataInsert": {
          name: 'dataInsert',
          type: 'Date',
          default: new Date(0)
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "municipality_id": {
          name: 'municipality_id',
          type: 'number'
        },
      },
      relations: {
        municipality: {
          name: 'municipality',
          type: 'Municipality',
          model: 'Municipality',
          relationType: 'belongsTo',
                  keyFrom: 'municipality_id',
          keyTo: 'id'
        },
      }
    }
  }
}
