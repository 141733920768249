/* tslint:disable */
import {
  Subscription,
  User
} from '../index';

declare var Object: any;
export interface ClientsPaymentInterface {
  "client_id": number;
  "datePayment": Date;
  "subscription_id": number;
  "price": number;
  "residualPrice": number;
  "discount": number;
  "vat": number;
  "valuePayment": number;
  "invoice"?: string;
  "id"?: number;
  subscriptions?: Subscription;
  owners?: User;
}

export class ClientsPayment implements ClientsPaymentInterface {
  "client_id": number;
  "datePayment": Date;
  "subscription_id": number;
  "price": number;
  "residualPrice": number;
  "discount": number;
  "vat": number;
  "valuePayment": number;
  "invoice": string;
  "id": number;
  subscriptions: Subscription;
  owners: User;
  constructor(data?: ClientsPaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ClientsPayment`.
   */
  public static getModelName() {
    return "ClientsPayment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ClientsPayment for dynamic purposes.
  **/
  public static factory(data: ClientsPaymentInterface): ClientsPayment{
    return new ClientsPayment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ClientsPayment',
      plural: 'ClientsPayments',
      path: 'ClientsPayments',
      idName: 'id',
      properties: {
        "client_id": {
          name: 'client_id',
          type: 'number'
        },
        "datePayment": {
          name: 'datePayment',
          type: 'Date'
        },
        "subscription_id": {
          name: 'subscription_id',
          type: 'number'
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "residualPrice": {
          name: 'residualPrice',
          type: 'number'
        },
        "discount": {
          name: 'discount',
          type: 'number'
        },
        "vat": {
          name: 'vat',
          type: 'number'
        },
        "valuePayment": {
          name: 'valuePayment',
          type: 'number'
        },
        "invoice": {
          name: 'invoice',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        subscriptions: {
          name: 'subscriptions',
          type: 'Subscription',
          model: 'Subscription',
          relationType: 'belongsTo',
                  keyFrom: 'subscription_id',
          keyTo: 'id'
        },
        owners: {
          name: 'owners',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'client_id',
          keyTo: 'id'
        },
      }
    }
  }
}
