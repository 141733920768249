/* tslint:disable */
import {
  User
} from '../index';

declare var Object: any;
export interface AssociationInterface {
  "all_properties": boolean;
  "automatic_police_transmission"?: string;
  "id"?: number;
  "owner_id"?: number;
  "pm_id"?: number;
  "cm_id"?: number;
  owner?: User;
  pms?: User;
  cms?: User;
}

export class Association implements AssociationInterface {
  "all_properties": boolean;
  "automatic_police_transmission": string;
  "id": number;
  "owner_id": number;
  "pm_id": number;
  "cm_id": number;
  owner: User;
  pms: User;
  cms: User;
  constructor(data?: AssociationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Association`.
   */
  public static getModelName() {
    return "Association";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Association for dynamic purposes.
  **/
  public static factory(data: AssociationInterface): Association{
    return new Association(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Association',
      plural: 'Associations',
      path: 'Associations',
      idName: 'id',
      properties: {
        "all_properties": {
          name: 'all_properties',
          type: 'boolean',
          default: false
        },
        "automatic_police_transmission": {
          name: 'automatic_police_transmission',
          type: 'string',
          default: 'NO'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
        "pm_id": {
          name: 'pm_id',
          type: 'number'
        },
        "cm_id": {
          name: 'cm_id',
          type: 'number'
        },
      },
      relations: {
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
        pms: {
          name: 'pms',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'pm_id',
          keyTo: 'id'
        },
        cms: {
          name: 'cms',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'cm_id',
          keyTo: 'id'
        },
      }
    }
  }
}
