/* tslint:disable */
export * from './Association';
export * from './Authorization';
export * from './Certificate';
export * from './Checkin';
export * from './ClientsCoupons';
export * from './ClientsPayment';
export * from './Coupon';
export * from './Country';
export * from './Device';
export * from './ExtraFoldersIstat';
export * from './Guest';
export * from './GuestType';
export * from './IdType';
export * from './Invitation';
export * from './Municipality';
export * from './Property';
export * from './Province';
export * from './Room';
export * from './Sending';
export * from './Subscription';
export * from './SubscriptionType';
export * from './UserOption';
export * from './TourtaxExemption';
export * from './TourtaxExemptionV2';
export * from './TourtaxUpload';
export * from './IstatUpload';
export * from './Variable';
export * from './QueueJob';
export * from './User';
export * from './RoleMapping';
export * from './Role';
export * from './TourtaxParameters';
export * from './LogUpdate';
export * from './GlobalParameters';
export * from './PropertyType';
export * from './Guide';
export * from './RoomsOccupied';
export * from './ContractUpload';
export * from './Host';
export * from './BaseModels';
export * from './FireLoopRef';
