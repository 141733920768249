/* tslint:disable */
import {
  Property,
  Checkin,
  User
} from '../index';

declare var Object: any;
export interface RoomInterface {
  "active"?: boolean;
  "name": string;
  "maxPax"?: number;
  "roomIdPlatform"?: string;
  "roomIdPlatformIstat"?: string;
  "id"?: number;
  "property_id"?: number;
  "owner_id"?: number;
  property?: Property;
  checkins?: Checkin[];
  owner?: User;
}

export class Room implements RoomInterface {
  "active": boolean;
  "name": string;
  "maxPax": number;
  "roomIdPlatform": string;
  "roomIdPlatformIstat": string;
  "id": number;
  "property_id": number;
  "owner_id": number;
  property: Property;
  checkins: Checkin[];
  owner: User;
  constructor(data?: RoomInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Room`.
   */
  public static getModelName() {
    return "Room";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Room for dynamic purposes.
  **/
  public static factory(data: RoomInterface): Room{
    return new Room(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Room',
      plural: 'Rooms',
      path: 'Rooms',
      idName: 'id',
      properties: {
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "maxPax": {
          name: 'maxPax',
          type: 'number'
        },
        "roomIdPlatform": {
          name: 'roomIdPlatform',
          type: 'string'
        },
        "roomIdPlatformIstat": {
          name: 'roomIdPlatformIstat',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "property_id": {
          name: 'property_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
      },
      relations: {
        property: {
          name: 'property',
          type: 'Property',
          model: 'Property',
          relationType: 'belongsTo',
                  keyFrom: 'property_id',
          keyTo: 'id'
        },
        checkins: {
          name: 'checkins',
          type: 'Checkin[]',
          model: 'Checkin',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accommodationId'
        },
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
      }
    }
  }
}
