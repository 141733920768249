/* tslint:disable */
import {
  Country,
  Checkin,
  GuestType,
  IdType,
  Municipality
} from '../index';

declare var Object: any;
export interface GuestInterface {
  "name": string;
  "surname": string;
  "gender": string;
  "birth": Date;
  "idNumber"?: string;
  "days": number;
  "tourtaxExemption"?: string;
  "extraFoldersIstat"?: string;
  "paid"?: number;
  "id"?: number;
  "citizenship_id"?: number;
  "country_id"?: number;
  "originId"?: number;
  "originType"?: string;
  "checkin_id"?: number;
  "issuerId"?: number;
  "issuerType"?: string;
  "guestType_id"?: number;
  "idType_id"?: number;
  "municipality_id"?: number;
  citizenship?: Country;
  country?: Country;
  origin?: any;
  checkin?: Checkin;
  issuer?: any;
  guestType?: GuestType;
  idType?: IdType;
  municipality?: Municipality;
}

export class Guest implements GuestInterface {
  "name": string;
  "surname": string;
  "gender": string;
  "birth": Date;
  "idNumber": string;
  "days": number;
  "tourtaxExemption": string;
  "extraFoldersIstat": string;
  "paid": number;
  "id": number;
  "citizenship_id": number;
  "country_id": number;
  "originId": number;
  "originType": string;
  "checkin_id": number;
  "issuerId": number;
  "issuerType": string;
  "guestType_id": number;
  "idType_id": number;
  "municipality_id": number;
  citizenship: Country;
  country: Country;
  origin: any;
  checkin: Checkin;
  issuer: any;
  guestType: GuestType;
  idType: IdType;
  municipality: Municipality;
  constructor(data?: GuestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Guest`.
   */
  public static getModelName() {
    return "Guest";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Guest for dynamic purposes.
  **/
  public static factory(data: GuestInterface): Guest{
    return new Guest(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Guest',
      plural: 'Guests',
      path: 'Guests',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "surname": {
          name: 'surname',
          type: 'string'
        },
        "gender": {
          name: 'gender',
          type: 'string'
        },
        "birth": {
          name: 'birth',
          type: 'Date'
        },
        "idNumber": {
          name: 'idNumber',
          type: 'string'
        },
        "days": {
          name: 'days',
          type: 'number'
        },
        "tourtaxExemption": {
          name: 'tourtaxExemption',
          type: 'string'
        },
        "extraFoldersIstat": {
          name: 'extraFoldersIstat',
          type: 'string'
        },
        "paid": {
          name: 'paid',
          type: 'number',
          default: 1
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "citizenship_id": {
          name: 'citizenship_id',
          type: 'number'
        },
        "country_id": {
          name: 'country_id',
          type: 'number'
        },
        "originId": {
          name: 'originId',
          type: 'number'
        },
        "originType": {
          name: 'originType',
          type: 'string'
        },
        "checkin_id": {
          name: 'checkin_id',
          type: 'number'
        },
        "issuerId": {
          name: 'issuerId',
          type: 'number'
        },
        "issuerType": {
          name: 'issuerType',
          type: 'string'
        },
        "guestType_id": {
          name: 'guestType_id',
          type: 'number'
        },
        "idType_id": {
          name: 'idType_id',
          type: 'number'
        },
        "municipality_id": {
          name: 'municipality_id',
          type: 'number'
        },
      },
      relations: {
        citizenship: {
          name: 'citizenship',
          type: 'Country',
          model: 'Country',
          relationType: 'belongsTo',
                  keyFrom: 'citizenship_id',
          keyTo: 'id'
        },
        country: {
          name: 'country',
          type: 'Country',
          model: 'Country',
          relationType: 'belongsTo',
                  keyFrom: 'country_id',
          keyTo: 'id'
        },
        origin: {
          name: 'origin',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'originId',
          keyTo: 'id'
        },
        checkin: {
          name: 'checkin',
          type: 'Checkin',
          model: 'Checkin',
          relationType: 'belongsTo',
                  keyFrom: 'checkin_id',
          keyTo: 'id'
        },
        issuer: {
          name: 'issuer',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'issuerId',
          keyTo: 'id'
        },
        guestType: {
          name: 'guestType',
          type: 'GuestType',
          model: 'GuestType',
          relationType: 'belongsTo',
                  keyFrom: 'guestType_id',
          keyTo: 'id'
        },
        idType: {
          name: 'idType',
          type: 'IdType',
          model: 'IdType',
          relationType: 'belongsTo',
                  keyFrom: 'idType_id',
          keyTo: 'id'
        },
        municipality: {
          name: 'municipality',
          type: 'Municipality',
          model: 'Municipality',
          relationType: 'belongsTo',
                  keyFrom: 'municipality_id',
          keyTo: 'id'
        },
      }
    }
  }
}
