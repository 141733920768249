import { Injectable } from '@angular/core';
import {InvitationApi} from "../../../sdk/services/custom";

@Injectable({
  providedIn: 'root'
})
export class InvitationDbService {

  constructor(
    private api : InvitationApi
  ) { }

  sendInvitation = async function (email:string){
    let data = await this.api.send({},email,{}).toPromise();
    return data
  }
}
