/* tslint:disable */

declare var Object: any;
export interface QueueJobInterface {
  "type": string;
  "model": string;
  "modelid": number;
  "extra"?: any;
  "status"?: string;
  "queuedTime": Date;
  "executionTime"?: Date;
  "id"?: number;
}

export class QueueJob implements QueueJobInterface {
  "type": string;
  "model": string;
  "modelid": number;
  "extra": any;
  "status": string;
  "queuedTime": Date;
  "executionTime": Date;
  "id": number;
  constructor(data?: QueueJobInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `QueueJob`.
   */
  public static getModelName() {
    return "QueueJob";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of QueueJob for dynamic purposes.
  **/
  public static factory(data: QueueJobInterface): QueueJob{
    return new QueueJob(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'QueueJob',
      plural: 'QueueJobs',
      path: 'QueueJobs',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'string'
        },
        "model": {
          name: 'model',
          type: 'string'
        },
        "modelid": {
          name: 'modelid',
          type: 'number'
        },
        "extra": {
          name: 'extra',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "queuedTime": {
          name: 'queuedTime',
          type: 'Date',
          default: new Date(0)
        },
        "executionTime": {
          name: 'executionTime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
