/* tslint:disable */
import {
  Property,
  User
} from '../index';

declare var Object: any;
export interface SendingInterface {
  "type": string;
  "from"?: Date;
  "to"?: Date;
  "data"?: any;
  "status"?: any;
  "results"?: any;
  "id"?: number;
  "property_id"?: number;
  "owner_id"?: number;
  property?: Property;
  owner?: User;
}

export class Sending implements SendingInterface {
  "type": string;
  "from": Date;
  "to": Date;
  "data": any;
  "status": any;
  "results": any;
  "id": number;
  "property_id": number;
  "owner_id": number;
  property: Property;
  owner: User;
  constructor(data?: SendingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Sending`.
   */
  public static getModelName() {
    return "Sending";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Sending for dynamic purposes.
  **/
  public static factory(data: SendingInterface): Sending{
    return new Sending(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Sending',
      plural: 'Sendings',
      path: 'Sendings',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'string'
        },
        "from": {
          name: 'from',
          type: 'Date'
        },
        "to": {
          name: 'to',
          type: 'Date'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'any'
        },
        "results": {
          name: 'results',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "property_id": {
          name: 'property_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
      },
      relations: {
        property: {
          name: 'property',
          type: 'Property',
          model: 'Property',
          relationType: 'belongsTo',
                  keyFrom: 'property_id',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
      }
    }
  }
}
