import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseurlService {
baseUrl:string
baseUrlImg : string 
radiceImg : string = '/assets/images/'
  constructor() { 
    let loc = window.location.hostname
console.log("loc ==============>",loc)
    if(loc=='localhost'){
      this.baseUrl = 'https://mulettofacilecheck-in.it'
      this.baseUrlImg = `${this.baseUrl}/admin${this.radiceImg}`
    }else if (loc =="mulettofacilecheck-in.it"){
      this.baseUrl = 'https://mulettofacilecheck-in.it'
      this.baseUrlImg = `${this.baseUrl}/admin${this.radiceImg}`
    }else{
      this.baseUrl = 'https://www.facilecheck-in.com'
      this.baseUrlImg = `${this.baseUrl}/panel${this.radiceImg}`
    }
  }

  getBaseUrl():string{
    return this.baseUrl
  }
  getBaseUrlImg():string{
    return this.baseUrlImg
  }
}
