import { Injectable, ɵConsole } from '@angular/core';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import {DataValidetorService} from '../services/data-validetor.service';


/**
 * Nel caso sia scaduto l'abbonamento nel campo scopes del rigo del token creato viene inserito 
 * questo valore che limita l'operatività del PM solo alle API che hanno nel remoteMethod
 * accessScopes : ["subscription"]
 * Inoltre verrà filtrato il menù alle sole operazioni abilitate
 */
 export const SUBSCRIPTION:string = "subscription" 

 
@Injectable({
  providedIn: 'root'
})
export class UserStoreService {
loginData :any ;
loginValue : any;
loginUser : any;
loc:any;

  constructor(
  ) { 
    
  }
  isEmpty= function (obj:any) {
    for(var i in obj) { return false; }
    return true;
  }
  getValueUser= function () {
    let ctr = new DataValidetorService
    this.loginData  = null
    this.loginValue = null
    this.loginUser  = null
    this.loginData  = JSON.parse(localStorage.getItem('auth_app_token'));
    //console.timeEnd('loginData')
    if(ctr.isFull(this.loginData)){
      this.loginValue = JSON.parse(this.loginData.value)
      this.loginUser = this.loginValue.user
    }
  }
  existToken = function(){
    let ctr = new DataValidetorService
    //console.time('existToken')
    this.getValueUser()
//console.log (`loginData ${ctr.isFull(this.loginData)}`)
    if(ctr.isFull(this.loginValue)){
      return true
    }else{
      return false
    }
    //console.timeEnd('existToken')
  }

  getToken= function () {
    //console.time('getToken')
    this.getValueUser()
    var value = this.loginValue.id || '';
    //console.timeEnd('getToken')
    return value;
  }
  getScopes= function () {
    //console.time('getScopes')
    this.getValueUser()
console.log("this.loginValue",this.loginValue)
    var value = this.loginValue.scopes || '';
    //console.timeEnd('getScopes')
    return value;
  }
  getUser= function () {
    this.getValueUser()
    var value = this.loginUser;
    return value;
  }
  getId= function () {
    //console.time('getId')
    this.getValueUser()
    var value = this.loginUser.id;
    //console.timeEnd('getId')
    return value;
  }

  getFirstname= function () {
    //console.time('getFirstname')
    this.getValueUser()
    var value = this.loginUser.firstname;
    //console.timeEnd('getFirstname')
    return value;
  }

  getLastname= function () {
    //console.time('getLastname')
    this.getValueUser()
    var value = this.loginUser.lastname;
    //console.timeEnd('getLastname')
    return value;
  }

  getFullname= function () {
    
    //console.time('getFullname')
    this.getValueUser()
    var value = this.loginUser.fullname || 'ND';
    //console.timeEnd('getFullname')
    return value;
  }

  getActive= function () {
    //console.time('getActive')
    this.getValueUser()
    var value = this.loginUser.active;
    //console.timeEnd('getActive')
    return value;
  }

  getEmail= function () {
    //console.time('getEmail')
    this.getValueUser()
    var value = this.loginUser.email;
    //console.timeEnd('getEmail')
    return value;
  }

  getBraintree_customer= function () {
    //console.time('getBraintree_customer')
    this.getValueUser()
    var value = this.loginUser.braintree_customer;
    //console.timeEnd('getBraintree_customer')
    return value;
  }

  getUser_id= function () {
    //console.trace()
    //console.time('getManager_id')
    this.getValueUser()
    var value = this.loginUser.id;
    //console.timeEnd('getManager_id')
    return value;
  }

  getManager_id= function () {
    //console.trace()
    //console.time('getManager_id')
    this.getValueUser()
    var value = this.loginUser.manager_id;
    //console.timeEnd('getManager_id')
    return value;
  }

  getRole_id= function () {
    //console.time('getRole_id')
    this.getValueUser()
    var value = this.loginUser.role_id;
    //console.timeEnd('getRole_id')
    return value;
  }
  getAgent= function () {
    //console.time('getRole_id')
    this.getValueUser()
    var value = this.loginUser.agent;
    //console.timeEnd('getRole_id')
    return value;
  }
  StorageL = {
    set: function (item, value) {
        localStorage.setItem(item, value);
    },
    get: function (item) {
        var value = localStorage.getItem(item);
        return value;
    },
    count: function () {
        var len = localStorage.length;
        return len;
    },
    remove: function (item) {
        localStorage.removeItem(item);
    },
    empty: function () {
        localStorage.clear();
        this.loginData = null
        this.loginValue = null
        this.loginUser = null
        //console.log('IsEmpty  dopo il logout:',this.isEmpty(this.loginData))
    //console.log('this.loginData dopo il logout',this.loginData)
    },
    saveObject: function (item, obj) {
        if (typeof obj === 'object') {
            this.set(item, JSON.stringify(obj));
        } else {
            this.set(item, 'Could not convert to JSON string');
        }
    },
    getObject: function (item) {
        var json = this.get(item);
        var obj = JSON.parse(json);
        return obj;
    }
};

}
