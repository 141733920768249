/* tslint:disable */
import {
  Certificate,
  Municipality,
  Checkin,
  Room,
  User,
  GeoPoint
} from '../index';

declare var Object: any;
export interface PropertyInterface {
  "active"?: boolean;
  "name": string;
  "type": string;
  "address": string;
  "cap": string;
  "city"?: string;
  "state"?: string;
  "coords"?: GeoPoint;
  "tourtaxExtra"?: any;
  "istatExtra"?: any;
  "exemptions"?: any;
  "dispoCamere"?: number;
  "dispoLetti"?: number;
  "dispoWc"?: number;
  "piattaforma"?: string;
  "propertyIdPlatform"?: string;
  "datiCatastali"?: any;
  "gestisciContratto"?: boolean;
  "elementiAccessoriContrattuali"?: string;
  "contratto"?: string;
  "host_id"?: number;
  "id"?: number;
  "municipality_id"?: number;
  "owner_id"?: number;
  certificates?: Certificate[];
  municipality?: Municipality;
  checkins?: Checkin[];
  rooms?: Room[];
  authorized?: User[];
  owner?: User;
}

export class Property implements PropertyInterface {
  "active": boolean;
  "name": string;
  "type": string;
  "address": string;
  "cap": string;
  "city": string;
  "state": string;
  "coords": GeoPoint;
  "tourtaxExtra": any;
  "istatExtra": any;
  "exemptions": any;
  "dispoCamere": number;
  "dispoLetti": number;
  "dispoWc": number;
  "piattaforma": string;
  "propertyIdPlatform": string;
  "datiCatastali": any;
  "gestisciContratto": boolean;
  "elementiAccessoriContrattuali": string;
  "contratto": string;
  "host_id": number;
  "id": number;
  "municipality_id": number;
  "owner_id": number;
  certificates: Certificate[];
  municipality: Municipality;
  checkins: Checkin[];
  rooms: Room[];
  authorized: User[];
  owner: User;
  constructor(data?: PropertyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Property`.
   */
  public static getModelName() {
    return "Property";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Property for dynamic purposes.
  **/
  public static factory(data: PropertyInterface): Property{
    return new Property(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Property',
      plural: 'properties',
      path: 'properties',
      idName: 'id',
      properties: {
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "cap": {
          name: 'cap',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "coords": {
          name: 'coords',
          type: 'GeoPoint'
        },
        "tourtaxExtra": {
          name: 'tourtaxExtra',
          type: 'any'
        },
        "istatExtra": {
          name: 'istatExtra',
          type: 'any'
        },
        "exemptions": {
          name: 'exemptions',
          type: 'any'
        },
        "dispoCamere": {
          name: 'dispoCamere',
          type: 'number'
        },
        "dispoLetti": {
          name: 'dispoLetti',
          type: 'number'
        },
        "dispoWc": {
          name: 'dispoWc',
          type: 'number'
        },
        "piattaforma": {
          name: 'piattaforma',
          type: 'string'
        },
        "propertyIdPlatform": {
          name: 'propertyIdPlatform',
          type: 'string'
        },
        "datiCatastali": {
          name: 'datiCatastali',
          type: 'any'
        },
        "gestisciContratto": {
          name: 'gestisciContratto',
          type: 'boolean'
        },
        "elementiAccessoriContrattuali": {
          name: 'elementiAccessoriContrattuali',
          type: 'string'
        },
        "contratto": {
          name: 'contratto',
          type: 'string'
        },
        "host_id": {
          name: 'host_id',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "municipality_id": {
          name: 'municipality_id',
          type: 'number'
        },
        "owner_id": {
          name: 'owner_id',
          type: 'number'
        },
      },
      relations: {
        certificates: {
          name: 'certificates',
          type: 'Certificate[]',
          model: 'Certificate',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'property_id'
        },
        municipality: {
          name: 'municipality',
          type: 'Municipality',
          model: 'Municipality',
          relationType: 'belongsTo',
                  keyFrom: 'municipality_id',
          keyTo: 'id'
        },
        checkins: {
          name: 'checkins',
          type: 'Checkin[]',
          model: 'Checkin',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accommodationId'
        },
        rooms: {
          name: 'rooms',
          type: 'Room[]',
          model: 'Room',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'property_id'
        },
        authorized: {
          name: 'authorized',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
          modelThrough: 'Authorization',
          keyThrough: 'property_id',
          keyFrom: 'id',
          keyTo: 'cm_id'
        },
        owner: {
          name: 'owner',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'owner_id',
          keyTo: 'id'
        },
      }
    }
  }
}
