/* tslint:disable */
import {
  Coupon,
  Property,
  Subscription,
  Certificate,
  SubscriptionType,
  Role
} from '../index';

declare var Object: any;
export interface UserInterface {
  "firstname": string;
  "lastname": string;
  "fullname"?: string;
  "active": boolean;
  "date_of_born"?: Date;
  "city_of_born"?: string;
  "gender"?: string;
  "cf"?: string;
  "address"?: string;
  "rag_soc"?: string;
  "vat_number"?: string;
  "email"?: string;
  "phone"?: string;
  "passwordToken": string;
  "cellular"?: string;
  "braintree_customer"?: string;
  "accept_terms"?: boolean;
  "accept_legal"?: boolean;
  "invoice"?: boolean;
  "sdi_code"?: string;
  "automatic_police_transmission"?: string;
  "agent"?: number;
  "nota_interna"?: string;
  "provenienza"?: string;
  "realm"?: string;
  "username"?: string;
  "emailVerified"?: boolean;
  "id"?: number;
  "manager_id"?: number;
  "subscriptionType_id"?: number;
  "created"?: Date;
  "modified"?: Date;
  "role_id"?: number;
  "password"?: string;
  managers?: User[];
  checkinManagers?: User[];
  couponUser?: Coupon[];
  manager?: User;
  collaborators?: User[];
  authorizations?: Property[];
  properties?: Property[];
  subscriptions?: Subscription[];
  certificates?: Certificate[];
  preferredSubscription?: SubscriptionType;
  accessTokens?: any[];
  role?: Role;
}

export class User implements UserInterface {
  "firstname": string;
  "lastname": string;
  "fullname": string;
  "active": boolean;
  "date_of_born": Date;
  "city_of_born": string;
  "gender": string;
  "cf": string;
  "address": string;
  "rag_soc": string;
  "vat_number": string;
  "email": string;
  "phone": string;
  "passwordToken": string;
  "cellular": string;
  "braintree_customer": string;
  "accept_terms": boolean;
  "accept_legal": boolean;
  "invoice": boolean;
  "sdi_code": string;
  "automatic_police_transmission": string;
  "agent": number;
  "nota_interna": string;
  "provenienza": string;
  "realm": string;
  "username": string;
  "emailVerified": boolean;
  "id": number;
  "manager_id": number;
  "subscriptionType_id": number;
  "created": Date;
  "modified": Date;
  "role_id": number;
  "password": string;
  managers: User[];
  checkinManagers: User[];
  couponUser: Coupon[];
  manager: User;
  collaborators: User[];
  authorizations: Property[];
  properties: Property[];
  subscriptions: Subscription[];
  certificates: Certificate[];
  preferredSubscription: SubscriptionType;
  accessTokens: any[];
  role: Role;
  constructor(data?: UserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `User`.
   */
  public static getModelName() {
    return "User";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of User for dynamic purposes.
  **/
  public static factory(data: UserInterface): User{
    return new User(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'User',
      plural: 'Users',
      path: 'Users',
      idName: 'id',
      properties: {
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "fullname": {
          name: 'fullname',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "date_of_born": {
          name: 'date_of_born',
          type: 'Date'
        },
        "city_of_born": {
          name: 'city_of_born',
          type: 'string'
        },
        "gender": {
          name: 'gender',
          type: 'string'
        },
        "cf": {
          name: 'cf',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "rag_soc": {
          name: 'rag_soc',
          type: 'string'
        },
        "vat_number": {
          name: 'vat_number',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "passwordToken": {
          name: 'passwordToken',
          type: 'string'
        },
        "cellular": {
          name: 'cellular',
          type: 'string'
        },
        "braintree_customer": {
          name: 'braintree_customer',
          type: 'string'
        },
        "accept_terms": {
          name: 'accept_terms',
          type: 'boolean'
        },
        "accept_legal": {
          name: 'accept_legal',
          type: 'boolean'
        },
        "invoice": {
          name: 'invoice',
          type: 'boolean'
        },
        "sdi_code": {
          name: 'sdi_code',
          type: 'string'
        },
        "automatic_police_transmission": {
          name: 'automatic_police_transmission',
          type: 'string',
          default: 'NO'
        },
        "agent": {
          name: 'agent',
          type: 'number',
          default: 0
        },
        "nota_interna": {
          name: 'nota_interna',
          type: 'string'
        },
        "provenienza": {
          name: 'provenienza',
          type: 'string'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "manager_id": {
          name: 'manager_id',
          type: 'number'
        },
        "subscriptionType_id": {
          name: 'subscriptionType_id',
          type: 'number'
        },
        "created": {
          name: 'created',
          type: 'Date',
          default: new Date(0)
        },
        "modified": {
          name: 'modified',
          type: 'Date',
          default: new Date(0)
        },
        "role_id": {
          name: 'role_id',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        managers: {
          name: 'managers',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
          modelThrough: 'Association',
          keyThrough: 'cm_id',
          keyFrom: 'id',
          keyTo: 'pm_id'
        },
        checkinManagers: {
          name: 'checkinManagers',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
          modelThrough: 'Association',
          keyThrough: 'pm_id',
          keyFrom: 'id',
          keyTo: 'cm_id'
        },
        couponUser: {
          name: 'couponUser',
          type: 'Coupon[]',
          model: 'Coupon',
          relationType: 'hasMany',
          modelThrough: 'ClientsCoupons',
          keyThrough: 'client_id',
          keyFrom: 'id',
          keyTo: 'coupon_id'
        },
        manager: {
          name: 'manager',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'manager_id',
          keyTo: 'id'
        },
        collaborators: {
          name: 'collaborators',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'manager_id'
        },
        authorizations: {
          name: 'authorizations',
          type: 'Property[]',
          model: 'Property',
          relationType: 'hasMany',
          modelThrough: 'Authorization',
          keyThrough: 'cm_id',
          keyFrom: 'id',
          keyTo: 'property_id'
        },
        properties: {
          name: 'properties',
          type: 'Property[]',
          model: 'Property',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'owner_id'
        },
        subscriptions: {
          name: 'subscriptions',
          type: 'Subscription[]',
          model: 'Subscription',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'owner_id'
        },
        certificates: {
          name: 'certificates',
          type: 'Certificate[]',
          model: 'Certificate',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'owner_id'
        },
        preferredSubscription: {
          name: 'preferredSubscription',
          type: 'SubscriptionType',
          model: 'SubscriptionType',
          relationType: 'belongsTo',
                  keyFrom: 'subscriptionType_id',
          keyTo: 'id'
        },
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        role: {
          name: 'role',
          type: 'Role',
          model: 'Role',
          relationType: 'belongsTo',
                  keyFrom: 'role_id',
          keyTo: 'id'
        },
      }
    }
  }
}
