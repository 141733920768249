/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { AssociationApi } from './services/custom/Association';
import { AuthorizationApi } from './services/custom/Authorization';
import { CertificateApi } from './services/custom/Certificate';
import { CheckinApi } from './services/custom/Checkin';
import { ClientsCouponsApi } from './services/custom/ClientsCoupons';
import { ClientsPaymentApi } from './services/custom/ClientsPayment';
import { CouponApi } from './services/custom/Coupon';
import { CountryApi } from './services/custom/Country';
import { DeviceApi } from './services/custom/Device';
import { ExtraFoldersIstatApi } from './services/custom/ExtraFoldersIstat';
import { GuestApi } from './services/custom/Guest';
import { GuestTypeApi } from './services/custom/GuestType';
import { IdTypeApi } from './services/custom/IdType';
import { InvitationApi } from './services/custom/Invitation';
import { MunicipalityApi } from './services/custom/Municipality';
import { PropertyApi } from './services/custom/Property';
import { ProvinceApi } from './services/custom/Province';
import { RoomApi } from './services/custom/Room';
import { SendingApi } from './services/custom/Sending';
import { SubscriptionApi } from './services/custom/Subscription';
import { SubscriptionTypeApi } from './services/custom/SubscriptionType';
import { UserOptionApi } from './services/custom/UserOption';
import { TourtaxExemptionApi } from './services/custom/TourtaxExemption';
import { TourtaxExemptionV2Api } from './services/custom/TourtaxExemptionV2';
import { TourtaxUploadApi } from './services/custom/TourtaxUpload';
import { IstatUploadApi } from './services/custom/IstatUpload';
import { VariableApi } from './services/custom/Variable';
import { QueueJobApi } from './services/custom/QueueJob';
import { UserApi } from './services/custom/User';
import { RoleMappingApi } from './services/custom/RoleMapping';
import { RoleApi } from './services/custom/Role';
import { TourtaxParametersApi } from './services/custom/TourtaxParameters';
import { LogUpdateApi } from './services/custom/LogUpdate';
import { GlobalParametersApi } from './services/custom/GlobalParameters';
import { PropertyTypeApi } from './services/custom/PropertyType';
import { GuideApi } from './services/custom/Guide';
import { RoomsOccupiedApi } from './services/custom/RoomsOccupied';
import { ContractUploadApi } from './services/custom/ContractUpload';
import { HostApi } from './services/custom/Host';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler,
    SocketConnection
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        AssociationApi,
        AuthorizationApi,
        CertificateApi,
        CheckinApi,
        ClientsCouponsApi,
        ClientsPaymentApi,
        CouponApi,
        CountryApi,
        DeviceApi,
        ExtraFoldersIstatApi,
        GuestApi,
        GuestTypeApi,
        IdTypeApi,
        InvitationApi,
        MunicipalityApi,
        PropertyApi,
        ProvinceApi,
        RoomApi,
        SendingApi,
        SubscriptionApi,
        SubscriptionTypeApi,
        UserOptionApi,
        TourtaxExemptionApi,
        TourtaxExemptionV2Api,
        TourtaxUploadApi,
        IstatUploadApi,
        VariableApi,
        QueueJobApi,
        UserApi,
        RoleMappingApi,
        RoleApi,
        TourtaxParametersApi,
        LogUpdateApi,
        GlobalParametersApi,
        PropertyTypeApi,
        GuideApi,
        RoomsOccupiedApi,
        ContractUploadApi,
        HostApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

